<template>
  <div class="grid">
    <div>{{ qrcode }}</div>
    <div class="col-12 lg:col-6 xl:col-3">
      <InputText v-model="resource" />
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <qrcode-vue :value="qrcode" :size="100" level="H" />
    </div>
  </div>
</template>
<script>
import { toBase64 } from "@/utils/common";
export default {
  data() {
    return {
      resource: null,
    };
  },
  computed: {
    qrcode() {
      if (this.resource) {
        return "https://fnt.vn/#/" + toBase64(this.resource);
      } else {
        return window.location.href;
      }
    },
    agent() {
      let msg = "";
      try {
        msg =
          navigator.userAgent +
          "#" +
          JSON.stringify(navigator.userAgentData) +
          "#" +
          navigator.platform;
      } catch (e) {
        msg = "error";
      }
      return msg;
    },
  },
};
</script>
